/* @font-face {
  font-family: 'Futura';
  src: url("../../assets/fonts/Futura.eot");
  src: local("☺"), 
  url("../../assets/fonts/Futura.woff") format("woff"), 
  url("../../assets/fonts/Futura.ttf") format("truetype"), 
  url("../../assets/fonts/Futura.svg") format("svg");
  font-weight: normal;
  font-style: normal; }
   */
.calculate p,div,span,option,button{
    font-family: 'Prompt';
    font-size: 16px;
  }
.calculate{
    margin: auto;
    width: 75%;
    max-width: 520px;
    /* color: white; */
    font-size: 20px;
    padding: 70px 0;
}

.subtitle {
  align-self: center;
  text-align: center ;
  color: gray;
}
/* .select{
      background-position: center;
      width: 180;
      margin-top : 9;
    } */
@media only screen and (max-width: 563px) {
    .select{
        
    }
}
@media only screen and (max-width: 765px) {
    .calculate{
        width: 100%;
    }
}
.calculate h1{
    padding-bottom:30px;
    margin-top: 50px;
}
.calculate p{
    font-size: 20px;
}
.box1{
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    margin-bottom: 0px;
}

.box1 img{
    height:40px;
    margin-right: 10px;
}
.boxinput{
    width:100%;
    color: white;
    border-color: transparent;
    font-size: 20px;
    /* margin-bottom: 20px; */
}
.box2{
    color: #2b4282;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    margin-bottom: 0px;
}
.box2 img{
    height: 40px;
    margin-right: 10px;
}
.buy{
    /* color: #2b4282; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    font-size: 20px;
    margin-bottom: 0px;
    border-radius: 12px;
    height: 50px;
    width: 100%;
    /* font-weight: bold; */
    border: 0px;
    /* background-color: #1e2f5d; */
    background-color: #eee;
    color: darkgray;
}
.sell{
    /* color: #2b4282; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    font-size: 20px;
    margin-bottom: 0px;
    border-radius: 12px;
    height: 50px;
    width: 100%;
    /* font-weight: bold; */
    border: 0px;
    /* background-color: #1e2f5d; */
    background-color: #eee;
    color: darkgray;
}

@media only screen and (max-width: 991px) {
    .poiter{
        height: 20px;
    }
}

.menu-active {
    background-color: orange;
    padding: 5px 8px;
    border-radius: 5px;
    color: white;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.buybox{
    padding-right:50;
    margin-bottom: 10px;
}
.sellbox{
    padding-left:50;
}
.active {
    background-color: darkorange;
    /* color: #2b4282; */
    color: white;
}
@media print {
    .calculate {
        display: none;
    }
  }

  /* style={{
    backgroundColor: 'transparent',
    color: 'white',
    border: 'none',
    outline: 'none',
    marginRight: 10
  }} */
  [include*="form-input-select()"] {
    display: block;
    padding: 0;
    position: relative;
    /* Set options to normal weight */
    /* ------------------------------------  */
    /* START OF UGLY BROWSER-SPECIFIC HACKS */
    /* ----------------------------------  */
    /* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container.
       The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex.
       You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
    /* Show only the native arrow */
    color: white;
    display: block;
    border-radius: 0;
    box-shadow: none;
    font-size: 20px;
    margin-top: 9px;
    margin-bottom: 15px;

    width: 30%;
  }
  [include*="form-input-select()"]::before, [include*="form-input-select()"]::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    z-index: 2;
  }
  [include*="form-input-select()"] select {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    font-size: 20px;
    outline: none;
    /* Focus style */
  }
  [include*="form-input-select()"] select:focus {
    background-color: transparent;
    outline: none;
  }
  [include*="form-input-select()"] option {
    font-weight: normal;
  }
  [include*="form-input-select()"] x:-o-prefocus, [include*="form-input-select()"]::after {
    display: none;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    [include*="form-input-select()"] select::-ms-expand {
      display: none;
    }
    [include*="form-input-select()"] select:focus::-ms-value {
      background: transparent;
      color: #000;
    }
  }
  @-moz-document url-prefix() {
    [include*="form-input-select()"] {
      overflow: hidden;
    }
    [include*="form-input-select()"] select {
      width: 120%;
      width: calc(100% + 3em);
      /* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
    }
    @supports (-moz-appearance: none) {
      [include*="form-input-select()"] select {
        width: 100%;
      }
    }
    [include*="form-input-select()"] select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
  @supports (-moz-appearance: none) {
    [include*="form-input-select()"] {
      width: 30%;
    }
  }
  [include*="form-input-select()"]::before, [include*="form-input-select()"]::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    border: 1px solid transparent;
    width: 0;
    height: 0;
    right: 16px;
  }
  [include*="form-input-select()"]::before {
    bottom: 55%;
    border-width: 0px;
    border-bottom-color: #D6D6D6;
  }
  [include*="form-input-select()"]::after {
    border-width: 0px;
    border-top-color: #D6D6D6;
    top: 55%;
  }
  @-moz-document url-prefix() {
    [include*="form-input-select()"] {
      border-right: 0px;
    }
    [include*="form-input-select()"]:hover {
      border-right: 0px;
    }
  }
  [include*="form-input-select()"]:hover select {
    /* box-shadow: 0 2px 3px rgba(0, 91, 166, 0.1) inset; */
    /* background-color: lightgray; */
    /* border-color: #005BA6; */
  }
  [include*="form-input-select()"]:hover select:focus {
    /* outline-color: transparent; */
    /* background-color: lightgray; */
  }
  [include*="form-input-select()"]:hover::before {
    /* border-bottom-color: #005BA6; */
    /* background-color: lightgray; */
  }
  [include*="form-input-select()"]:hover::after {
    /* border-top-color: #005BA6; */
    /* background-color: lightgray; */
  }
  [include*="form-input-select()"] select {
    /* box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 3px solid #E6E6E6; */
    /* background-color: gray; */
    border-radius: 0;
    font-weight: 400;
    color: black;
    padding: 9px 10px;
    line-height: normal;
    transition: border-color 0.2s ease, outline 0.2s ease;
  }
  [include*="form-input-select()"] select:focus {
    /* box-shadow: 0 3px 4px rgba(0, 91, 166, 0.3) inset; */
    outline: none;
    /* background-color: gray; */
    /* outline: 3px solid #005BA6; */
    /* outline-offset: -3px; */
  }
  [include*="form-input-select()"] select[disabled], [include*="form-input-select()"] select:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  [include*="form-input-select()"] select:not(:focus):invalid {
    color: #C7C7C7;
  }
  
  h2 {
    color: black;
    align-self: center;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: orange;
    text-underline-position: under;
    margin-bottom: 5px;
  }