body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    height:100%;
    page-break-before: avoid;
  }
}