.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  background: rgb(255,138,46);
  background: radial-gradient(circle, rgba(255,138,46,1) 0%, rgba(252,176,69,1) 100%);
}
.footer .head {
  background-color: orange;
  /* font-size: 25px; */
  color: white;
  width: 100%;
  text-align: center;
  /* padding: 5px 0; */
  height: 3px;
}

.footer .body {
  background-color: #454545;
  width: 100%;
  padding: 30px 0;
}

.footer .body .small {
  font-size: 12px !important;
}

.footer h5 {
  margin-bottom: 30px;
}

.footer a {
  color: white;
}

.copyright {
  background-color: #252525;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  margin-bottom: 0px;
  color: white;
}

.footer h4 {
  margin: 40px 0;
  color: white;
  font-size: 28px;
  font-weight: bold;
}

.social { display: flex; }

.social-detail { 
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.social-name {
  font-size: 15px;
  margin-top: 3px;
  color: white;
  font-weight: bold;
  /* font-weight: bold; */
}

.social-account {
  font-size: 15px;
  /* color: #333; */
  color: white;
}

.control-label {
  font-size: 13px;
  margin-bottom: 0px;
  color: white;
}